.sv-formFieldRow,
.sv-formField {
  box-sizing: border-box;
}

/* clearfix */
.sv-formFieldRow:after {
  content: '';
  display: table;
  clear: both;
}

.sv-formField {
  float: left;
  width: 100%;
}

@media (min-width: 400px) {
  .sv-formField--cols-3,
  .sv-formField--cols-4,
  .sv-formField--cols-6 {
    width: 50%;
    padding: 0 10px 0 0;
  }
  .sv-formField--cols-3:nth-of-type(even),
  .sv-formField--cols-4:nth-of-type(even),
  .sv-formField--cols-6:last-child {
    padding: 0 0 0 10px;
  }
  .sv-formField--cols-4:last-child {
    width: 100%;
    padding: 0;
  }
}

@media (min-width: 600px) {
  .sv-formField--cols-4 {
    width: 33.333%;
    padding: 0 7px;
  }
  .sv-formField--cols-4:first-child {
    padding: 0 13px 0 0;
  }
  .sv-formField--cols-4:last-child {
    width: 33.333%;
    padding: 0 0 0 13px;
  }
}

@media (min-width: 800px) {
  .sv-formField--cols-3 {
    width: 25%;
    padding: 0 15px 0 0;
  }
  .sv-formField--cols-3:nth-child(2) {
    padding: 0 10px 0 5px;
  }
  .sv-formField--cols-3:nth-child(3) {
    padding: 0 5px 0 10px;
  }
  .sv-formField--cols-3:nth-child(4) {
    padding: 0 0 0 15px;
  }
}

/*==== Form Default Theme ====*/

.sv-defaultFormTheme button,
.sv-defaultFormTheme input[type='button'],
.sv-defaultFormTheme input[type='reset'],
.sv-defaultFormTheme input[type='submit'] {
  appearance: button;
  cursor: pointer;
}

.sv-defaultFormTheme button[disabled],
.sv-defaultFormTheme input[disabled] {
  cursor: default;
}

.sv-defaultFormTheme input[type='checkbox'],
.sv-defaultFormTheme input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.sv-defaultFormTheme input[type='file']:focus,
.sv-defaultFormTheme input[type='radio']:focus,
.sv-defaultFormTheme input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.sv-defaultFormTheme input[type='file'] {
  width: 100%;
  padding: 5px;
}

.sv-defaultFormTheme input[type='button'],
.sv-defaultFormTheme input[type='submit'],
.sv-defaultFormTheme input[type='reset'] {
  display: inline-block;
  padding: 4px 12px;
  margin-bottom: 0;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.sv-defaultFormTheme input[type='button']:focus,
.sv-defaultFormTheme input[type='submit']:focus,
.sv-defaultFormTheme input[type='reset']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.sv-defaultFormTheme input[type='button']:hover,
.sv-defaultFormTheme input[type='submit']:hover,
.sv-defaultFormTheme input[type='reset']:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.sv-defaultFormTheme input[type='button']:hover,
.sv-defaultFormTheme input[type='submit']:hover,
.sv-defaultFormTheme input[type='reset']:hover,
.sv-defaultFormTheme input[type='button']:focus,
.sv-defaultFormTheme input[type='submit']:focus,
.sv-defaultFormTheme input[type='reset']:focus {
  color: #333;
  text-decoration: none;
}

.sv-defaultFormTheme input[type='button']:active,
.sv-defaultFormTheme input[type='submit']:active,
.sv-defaultFormTheme input[type='reset']:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.sv-defaultFormTheme input[type='button'][disabled],
.sv-defaultFormTheme input[type='submit'][disabled],
.sv-defaultFormTheme input[type='reset'][disabled] {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.65;
}

.sv-defaultFormTheme input[type='text'],
.sv-defaultFormTheme input[type='email'],
.sv-defaultFormTheme input[type='url'],
.sv-defaultFormTheme input[type='tel'],
.sv-defaultFormTheme input[type='number'],
.sv-defaultFormTheme textarea,
.sv-defaultFormTheme select {
  display: block;
  box-sizing: border-box;
  color: #555;
  background-color: #fff;
  background-image: none;
  width: 100%;
  padding: 0 0 0 12px;
  line-height: normal;
  height: 2em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.sv-defaultFormTheme input[type='text']:focus,
.sv-defaultFormTheme input[type='email']:focus,
.sv-defaultFormTheme input[type='url']:focus,
.sv-defaultFormTheme input[type='tel']:focus,
.sv-defaultFormTheme input[type='number']:focus,
.sv-defaultFormTheme textarea:focus,
.sv-defaultFormTheme select:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.sv-defaultFormTheme input[type='text'][disabled],
.sv-defaultFormTheme input[type='email'][disabled],
.sv-defaultFormTheme input[type='url'][disabled],
.sv-defaultFormTheme input[type='tel'][disabled],
.sv-defaultFormTheme input[type='number'][disabled],
.sv-defaultFormTheme textarea[disabled],
.sv-defaultFormTheme select[disabled] {
  background-color: #eee;
  opacity: 1;
  cursor: not-allowed;
}

.sv-defaultFormTheme textarea {
  height: auto;
  resize: vertical;
}

.sv-defaultFormTheme .sv-field-title-container.sv-bold label,
.sv-defaultFormTheme .sv-field-title-container.sv-bold span,
.sv-defaultFormTheme .sv-field-title-container.sv-bold h1,
.sv-defaultFormTheme .sv-field-title-container.sv-bold h2,
.sv-defaultFormTheme .sv-field-title-container.sv-bold h3,
.sv-defaultFormTheme .sv-field-title-container.sv-bold h4,
.sv-defaultFormTheme .sv-field-title-container.sv-bold h5,
.sv-defaultFormTheme .sv-field-title-container.sv-bold h6 {
  font-weight: bold;
}

.sv-defaultFormTheme select[multiple] {
  height: auto;
}

.sv-defaultFormTheme .sv-form-datefield select {
  display: inline-block;
  width: auto;
}

.sv-defaultFormTheme .sv-form-singleselectionfield label,
.sv-defaultFormTheme .sv-form-multipleselectionfield label {
  cursor: pointer;
}

.sv-form-orderfield td input[type='number'] {
  width: 80px;
}

.sv-formField .sv-has-error fieldset.sv-form-singleselectionfield,
.sv-formField .sv-has-error fieldset.sv-form-singleselectionfield,
.sv-formField .sv-has-error .sv-form-orderfield,
.sv-formField .sv-has-error .sv-form-datefield,
.sv-formField .sv-has-error .sv-form-gradefield,
.sv-formField .sv-has-error fieldset.sv-form-subalternativefield,
.sv-formField .sv-form-consent.sv-has-error {
  background-color: #ffcccb;
  outline: 1px solid #a94442;
}

.sv-formField .sv-has-error [aria-invalid],
.sv-formField .sv-has-error[aria-invalid],
.sv-formField .sv-has-error .sv-form-singleselectionfield,
.sv-formField .sv-has-error .sv-form-multipleselectionfield,
.sv-formField .sv-has-error .sv-form-textfield,
.sv-formField .sv-form-userattributefield .sv-has-error input,
.sv-formField .sv-form-userattributefield input.sv-has-error,
.sv-formField .sv-has-error .sv-form-filefield,
.sv-formField .sv-has-error .sv-form-participantsfield input,
.sv-formField .sv-has-error .sv-form-recipientsfield {
  background-color: #ffcccb;
  border: 1px solid #a94442;
}

.sv-formField [role='alert'] {
  color: #a94442;
}

.sv-formField .sv-form-consent {
  position: relative;
  padding-left: 1.8em;
}

.sv-formField .sv-form-consent input[type='checkbox'] {
  position: absolute;
  left: 0;
}

.sv-formField .sv-textfield-input-size {
  float: right;
}

.sv-legacyForm .sv-textfield-input-size {
  display: block;
}

.sv-textfield-limit-exceeded {
  color: #a94442;
}

.sv-booking-form .sv-form-orderfield input[type='number'] {
  width: 40px;
}

.sv-booking-form .sv-textfield-input-size {
  display: none;
}
