body.sv-dialog-open {
   overflow: hidden;
}

.sv-dialog-container {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1040;
   overflow-x: hidden;
   overflow-y: auto;
   outline: 0;
}

.sv-dialog-backdrop {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background-color: rgb(0, 0, 0);
   background-color: rgba(0, 0, 0, 0.5);
}

.sv-dialog {
   position: relative;
   width: auto;
   margin: 10px;
}

.sv-dialog-movable .sv-dialog-header {
   cursor: move
}

@media (min-width: 768px) {
   .sv-dialog {
      width: 600px;
      margin: 30px auto;
   }
}

.sv-dialog-content {
   position: relative;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #999;
   border: 1px solid rgba(0,0,0,.2);
   border-radius: 6px;
   outline: 0;
   box-shadow: 0 3px 9px rgba(0,0,0,.5);
}
.sv-dialog-content input[type=text].sv-tellfriend,
.sv-dialog-content select.sv-tellfriend,
.sv-dialog-content textarea.sv-tellfriend {
   box-sizing: border-box;
   max-width: 100%;
   margin: 0;
}

.sv-dialog-content textarea.sv-tellfriend,
.sv-dialog-content input[type=text].sv-tellfriend {
   display: inline-block;
   padding: 4px;
   font-size: 13px;
   line-height: 18px;
   color: #555;
   border: 1px solid #ccc;
   border-radius: 3px;
   margin-bottom:15px;
}

.sv-dialog-header,
.sv-dialog-body,
.sv-dialog-footer {
    padding: 1em;
}

.sv-dialog-header,
.sv-dialog-body {
    border-bottom: 1px solid #e5e5e5;
}

.sv-dialog-header h4 {
    margin: 0;
    font-size: 18px;
    line-height: 1.42857143;
    vertical-align: bottom;
}

.sv-dialog button.sv-dialog-close {
   cursor: pointer;
   float: right;
   border: none;
   background-color: #FFF;
   color: gray;
   font-weight: bold;
   font-size: 18px;
   padding: 0;
   vertical-align: bottom;
   margin: 0;
}

.sv-dialog-footer .sv-dialog-btn {
    float: right;
    margin-left: 1em;

    border: 1px solid #ccc;

    color: #333;
    background-color: #fff;

    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border-radius: 4px;
}

.sv-dialog-footer .sv-dialog-btn:hover {
   color: #333;
   background-color: #e6e6e6;
   border-color: #adadad;
}

.sv-dialog-footer .sv-dialog-btn.sv-dialog-btn-primary {
   color: #fff;
   background-color: #337ab7;
   border-color: #2e6da4;
}

.sv-dialog-footer .sv-dialog-btn.sv-dialog-btn-primary:hover {
   color: #fff;
   background-color: #286090;
   border-color: #204d74;
}

.sv-dialog-footer .sv-dialog-btn.sv-dialog-btn-primary:disabled,
.sv-dialog-footer .sv-dialog-btn.sv-dialog-btn-primary:disabled:hover {
   color: #9c9797;
   background-color: #d3d6d8;
   border-color: #fff;
   cursor: not-allowed;
}

.sv-dialog-footer .sv-dialog-btn.sv-dialog-btn-link:hover {
   color: #23527c;
   text-decoration: underline;
   background-color: transparent;
}

.sv-dialog-footer .sv-dialog-btn.sv-dialog-btn-link,
.sv-dialog-footer .sv-dialog-btn.sv-dialog-btn-link:hover {
   border-color: transparent;
}

.sv-dialog-footer .sv-dialog-btn.sv-dialog-btn-link {
   font-weight: 400;
   color: #337ab7;
   border-radius: 0;
}

.sv-dialog-header:before,
.sv-dialog-header:after,
.sv-dialog-body:before,
.sv-dialog-body:after,
.sv-dialog-footer:before,
.sv-dialog-footer:after {
   content:" ";
   display:table
}

.sv-dialog-header:after,
.sv-dialog-body:after,
.sv-dialog-footer:after {
   clear:both
}

.sv-dialog-body {
   max-height: 600px;
   overflow: hidden;
   overflow-y: scroll;
}