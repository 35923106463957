.sv-searchform2-portlet .sv-searchform2-form .sv-searchform2-input {
  height: 30px;
  box-sizing: border-box;
}

.sv-searchform2-portlet .sv-searchform2-form .sv-searchform2-result-container {
  display: none;
  background-clip: padding-box;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1010;
  width: 500px;
}

.sv-searchform2-portlet .sv-searchform2-facet-column0 {
  float: left;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;
  box-sizing: border-box;
}

.sv-searchform2-portlet .sv-user-search-list {
  margin-top: 7px;
}

.sv-searchform2-portlet .sv-searchform2-content-header {
  display: block !important;
  margin-top: 7px !important;
  padding-left: 7px !important;
}
.sv-searchform2-portlet .sv-searchform2-content-container {
  float: left;
}

.sv-searchform2-portlet .sv-searchform2-facet-column1 {
  float: left;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;
  box-sizing: border-box;
  border-left: 1px #eeeeee solid;
}

.sv-searchform2-portlet .sv-searchform2-facet-column2 {
  float: right;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;
  box-sizing: border-box;
  border-left: 1px #eeeeee solid;
}

.sv-searchform2-portlet li.sv-searchform2-search-hit {
  border-top: 1px solid #eeeeee;
  padding: 3px !important;
}

.sv-searchform2-portlet li.sv-searchform2-search-hit:first-child {
  border-top: none;
}

.sv-searchform2-portlet li.sv-searchform2-more-hits {
  padding: 4px 3px 3px 3px !important;
  text-align: right;
}

.sv-searchform2-portlet .sv-searchform2-user-img {
  float: left;
}

.sv-searchform2-portlet .sv-searchform2-user-container {
  overflow: hidden;
  padding-left: 4px;
  vertical-align: top;
}

.sv-searchform2-portlet .sv-searchform2-user-name {
  display: block !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal;
}

.sv-searchform2-portlet .sv-searchform2-userfield-list {
  display: block !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal;
}

.sv-searchform2-portlet .sv-searchform2-userfield-list li {
  padding: 0;
}

.sv-searchform2-portlet .sv-searchform2-message {
  padding: 0.5em;
}

.sv-wrap-words {
  word-wrap: break-word;
}

