.sv-iframeEmbed-4_3,
.sv-iframeEmbed-16_9 {
   position: relative;
   height: 0;
   clear: both;
}

.sv-iframeEmbed-4_3 iframe,
.sv-iframeEmbed-4_3 embed,
.sv-iframeEmbed-16_9 iframe,
.sv-iframeEmbed-16_9 embed {
   position: absolute;
   top: 0;
   left: 0;
   width: 100% !important;
   height: 100% !important;
}

.sv-iframeEmbed-4_3 {
   padding-bottom: 75%;
}

.sv-iframeEmbed-4_3 iframe,
.sv-iframeEmbed-4_3 embed {
   max-width: none !important;
   max-height: none !important;
}

.sv-iframeEmbed-16_9 {
   padding-bottom: 56.25%;
}

.sv-iframeEmbed-16_9 iframe,
.sv-iframeEmbed-16_9 embed {
   max-width: none !important;
   max-height: none !important;
}

.sv-oembed-container .fb-post,
.sv-oembed-container .giphy-img,
.sv-oembed-container .fb_iframe_widget {
   width: 100%;
}