.sv-login-portlet fieldset {
   border: none;
}

.sv-login-portlet input,
.sv-login-portlet label {
   display:block;
}

.sv-login-portlet .sv-login-input-group {
   margin-bottom: 5px;
}

.sv-login-portlet .sv-checkbox label,
.sv-login-portlet .sv-checkbox input {
   display: inline;
}

.sv-login-input-group input:invalid {
   outline: none;
   box-shadow: none;
   color: rgb(85, 85, 85);
}