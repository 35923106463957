.sv-defaultlist {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-color: #aaa !important;
}

.sv-defaultlist-noleft {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}