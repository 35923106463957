.sv-archive-portlet .sv-comments-input {
  width: 100%;
}

.sv-archive-portlet .sv-buddy-icon {
  margin-right: 5px;
}

.sv-archive-portlet form.sv-comments-form {
  overflow: hidden;
}

.sv-archive-portlet .sv-input-section {
  padding: 1em;
}

.sv-archive-portlet .sv-message {
  display: none;
  width: 100%;
  overflow: hidden;
}

.sv-archive-portlet .sv-message p {
  text-align: center;
}

.sv-archive-portlet .sv-comment-container {
  display: none;
}

.sv-archive-portlet .sv-like i,
.sv-archive-portlet .sv-comment i {
  margin-right: 1px;
  vertical-align: middle;
}

.sv-archive-portlet .sv-like {
  margin-right: 10px;
}
