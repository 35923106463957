.sv-channel-paragraph {
  display: block;
  margin-bottom: 1em;
}

.sv-channelmenu-use-img {
  background-repeat: no-repeat;
  background-position: 0 0;
  padding-left: 1.5em;
}
