.sv-sharepointfile-files .halflings-icon.lock {
   opacity: .6;
   vertical-align: middle;
}

.sv-sharepointfile-files .menu-label {
   color: #858585;
}

.sv-sharepointfile-files button {
   float: right;
}

.sv-sharepointfile-root ol {
   background-color: #f5f5f5;
   padding: 8px 15px;
   margin: 0 0 20px;
}

.sv-sharepointfile-breadcrumbs a,
.sv-sharepointfile-files .env-dropdown__menu a {
   text-decoration: none;
}

.sv-sharepointfile-root table tr {
   height: 40px;
}