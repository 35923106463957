.pagination a {
   text-decoration:none;
   border:1px solid #CBCBCB;
   color:#404040
}

.pagination a:hover {
   background:none repeat scroll 0 0 #F3F3F3;
   color:#404040
}

.pagination a,
.pagination span {
   display:block;
   float:left;
   padding:0.3em 0.5em;
   margin-right:5px;
   margin-bottom:5px;
   min-width:1em;
   text-align:center
}

.pagination .current {
   background:none repeat scroll 0 0 #F4F4F4;
   border:1px solid #CBCBCB;
   font-weight:bold
}

.pagination .current.prev,
.pagination .current.next {
   border:1px solid #EDEDED;
   color:#CCCCCC;
   font-weight:normal;
   background-color:#FFFFFF
}

.sv-paging-top-margin {
  margin-top: 0.7em;
}