.sv-sharepoint-error {
  display: table-row;
}

.sv-sharepoint-error div {
  display: table-cell;
  padding-left: 5px;
  vertical-align: top;
}

.sv-sharepointsearchfilelist-portlet ul,
.sv-sharepointfile-portlet ul {
  left: auto;
  right: 0;
}

.sv-sharepointfile-portlet ul li a {
  display: flex;
}

.sv-sharepointsearchfile-files .halflings-icon.lock {
  opacity: 0.6;
  vertical-align: middle;
}

.sv-sharepointsearchfile-files .menu-label {
  color: #858585;
}

.sv-sharepointsearchfile-files button {
  float: right;
}

.sv-sharepointsearchfile-files .env-dropdown__menu a {
  text-decoration: none;
}
