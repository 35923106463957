
.sv-imgslideshow-item {
   position: relative;
}

.sv-imgslideshow-item__description-wrapper {
   position: absolute;
   top: .25em;
   right: 0;
   left: 0;
   text-align: center;
}

.sv-imgslideshow-item__description {
   display: inline-block;
   background-color: #000;
   background-color: rgba(0, 0, 0, 0.4);
   color: #fff;
   line-height: 1;
   padding: .5em 1em;
   border-radius: 1em;
}

.sv-carousel_container {
  position: relative;
}

.sv-carousel_togglePlay_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #000;
  border-radius: 50%;
  border: 3px solid #fff;
  opacity: 0;
  transition: all 0.5s ease 0s;
  width: 32px;
  height: 32px;
  color: #fff;
  display: block;
  font-family: sans-serif;
  font-size: 22px;
  line-height: 1;
  text-align: center;
  padding: 0;
}

.sv-carousel_container:hover > .sv-carousel_togglePlay_btn, .sv-carousel_togglePlay_btn:focus {
  opacity: 1;
  cursor: pointer;
}

.imgSlideshowPlayIcon {
   margin-left: 2px;
}